<!-- 复制的5Gpackage，用该链接挂新的尾量 -->
<template>
  <div class="container">
    <img class="call-icon" @click="customerVisibility = true"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/henanYD/15yuanEnjoy40G_icon.png" />
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/henanYD/5Gpackage_top.png" />

    <van-form>
      <img class="step1" v-if="num == 1" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif" />
      <van-field v-model="form.phone" type="tel" maxlength="11" placeholder="请输入中国移动手机号码(已加密)" @input="changePhone" />
      <img class="submit" @click="onSubmit"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/henanYD/submit_lijidinggou.png" />
    </van-form>

    <div class="info">
      <div class="title">订购说明</div>
      <div class="text">1、参与限制：仅限有当前产品办理资格的用户参与。</div>
      <div class="text">
        2、合约活动内容:参与活动时需要为您订购5G流量包-娱乐版6个月合约(电渠专属)活动，连带开通10元5G流量包(标准资费10元/月)和咪咕视频黄金会员(标准资费9.9元/月)，
        <span class="yellow">活动价15元/月，合约期6个自然月(含当月)。</span>
      </div>
      <div class="text">
        3、包含资源：每月含<span class="yellow">5GB国内(除港澳台地区)通用流量+咪咕视频黄金会员。</span>
      </div>
      <div class="text">
        4、合约承诺及违约约定:合约期内需承诺在网并履行约定，如在
        <span class="yellow">合约期内(含参与当月)电请信机，销号，携号转网等服务需至当地移动自营厅解约后办理，</span>
        解除合约按河南移动合约活动提前解约方室执行。
      </div>
      <div class="text">
        5、合约活动到期：<span class="yellow">合约期最后五天前不可取消10元5G流量包、咪咕视频黄金会员</span>
        ，活动到期后产品将<span class="yellow">自动续订</span>且按<span
          class="yellow">10元5G流量包(标准资费10元/月)和咕视频黄金会员(标准资费9.9元/月)</span>收费，，若您不需要可根据到期提醒短信主动退订产品，<span
          class="yellow">不退订视为继续使用。</span>
      </div>
      <div class="text">
        6、流量优先级：产品包含国内通用优先级低于主套餐套内流量，限当月使用，不可共享，转赠，流量使用遵循基础套餐的超套流量标准、封顶、限速规则执行。
      </div>
      <div class="call">客服电话：<u>400-118-9599</u></div>
    </div>

    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/henanYD/5Gpackage_qdbs.jpg" />

    <!-- 客服弹窗 -->
    <v-dialog-customer-service v-if="customerVisibility" :visibility.sync="customerVisibility"
      background="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/henanYD/15yuanEnjoy40G_call.png" />
    <!-- 确认订购 -->
    <v-dialog v-if="visibility" :visibility.sync="visibility" :form.sync="form" />

    <!-- 受理成功 -->
    <div class="dialog" v-show="successDialogVisibility" @click="successDialogVisibility = false">
      <div class="success">
        <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/henanYD/5Gpackage_success.png" alt="">
      </div>
    </div>

    <!-- 二确 -->
    <BaseDialogConfirm :BaseDialogvisibility.sync="BaseDialogvisibility" :closeBtnImg="closeBtnImg"
      :submitBtnImg="submitBtnImg" :background="background" />
  </div>
</template>

<script>
import VDialogCustomerService from "./dialog/customerService.vue";
import VDialog from "./dialog/5Gpackage4";
import BaseDialogConfirm from "./dialog/confirm.vue";
import { tradeOrder, cityPhoneCheck } from "@/api/qiuMoFusion/index.js";
import tailJumpMixin from "@/utils/tailJump.js";

export default {
  mixins: [tailJumpMixin],
  components: {
    VDialogCustomerService,
    VDialog,
    BaseDialogConfirm
  },
  data() {
    return {
      form: {
        productNo: "X14202402002001",
        phone: "",
        characteristic: "",
        marketUrl: "",
        sid: "",
        data: "",
        code: "",
        orderId: "",
      },
      num: 1,
      customerVisibility: false,
      visibility: false,
      successDialogVisibility: false,
      BaseDialogvisibility: false,
      origin: "",
      closeBtnImg: "https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/henan/alert_cancel.png",
      submitBtnImg: "https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/henan/alert_ok.png",
      background: "https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/henan/alert_bg03.png",
    };
  },
  computed: {
    link() {
      return `${location.origin}/pages/qiuMoFusion/YD/henan/50Gpackage`;
    },
  },
  created() {
    const { origin } = location;
    this.origin = origin;
    document.title = "5G流量包-娱乐版6个月合约(电渠专属)";
    const { from = "", phone = "", reasonCode = "" } = this.$route.query;
    const { href = "" } = window.location;
    const stroagePhone = localStorage.getItem("phone");
    this.form = {
      ...this.form,
      characteristic: from + reasonCode,
      marketUrl: href,
      phone: !!phone ? phone : stroagePhone !== "null" ? stroagePhone : "",
    };
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  mounted() {
    const that = this;
    window.onpopstate = function (event) {
      // 返回拦截
      window.location.replace(
        that.tailJumpUrl(that.link, `QIUMORONGHE`, that.form.phone, "退出")
      );
    };
  },
  methods: {
    async getHnPhoneCheck(phone) {
      const { data = "" } = await cityPhoneCheck(phone);
      if (data.indexOf("移动") >= 0) {
        if (data.indexOf("郑州") >= 0) {
          this.BaseDialogvisibility = true;
        } else {
          this.handleSubmit();
        }
      }
    },
    changePhone(value) {
      if (value.length == 11) {
        localStorage.setItem("phone", value);
      }
    },
    onSubmit() {
      let filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else {
        this.visibility = true;
      }
    },
    submitFrom() {
      this.getHnPhoneCheck(this.form.phone);
    },
    handleSubmit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      tradeOrder(JSON.stringify(this.form)).then((res) => {
        this.$toast.clear();
        this.visibility = false;
        this.BaseDialogvisibility = false;
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message || '提交失败',
          });
          setTimeout(() => {
            window.location.replace(
              this.tailJumpUrl(
                this.link,
                `QIUMORONGHE`,
                this.form.phone,
                "失败"
              )
            );
          }, 3000);
        } else {
          this.$dialog.alert({
            title: "提交成功",
            message: "具体结果，请以短信提示为准",
            messageAlign: "left",
          });
          setTimeout(() => {
            window.location.replace(
              this.tailJumpUrl(
                this.link,
                `QIUMORONGHE`,
                this.form.phone,
                "成功"
              )
            );
          }, 3000);
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #da1240;

  .call-icon {
    position: fixed;
    right: 0px;
    top: 122px;
    width: 130px;
    z-index: 1;
  }

  .img {
    width: 100%;
  }

  ::v-deep .van-form {
    background: transparent;
    position: relative;
    padding: 80px 0 0px;
    position: relative;

    .step1 {
      width: 326px;
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }

    .step1 {
      transform: translate(80px, -80px);
    }

    .van-cell {
      width: 670px;
      height: 110px;
      background-color: #ffffff;
      border-radius: 80px;
      margin: 0 auto;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 40px;

      &.input-code {
        margin-top: 45px;

        .code {
          width: 229px;
          height: 110px;
          text-align: center;
          line-height: 110px;
          color: #09002a;
          font-size: 28px;
          font-weight: normal;
          // border-left: 2px solid #d14c1e;
          background-color: #ffe27a;
        }
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        font-size: 36px;
        font-weight: 400;

        &::-webkit-input-placeholder {
          font-size: 36px;
          font-weight: 400;
          color: #caccce;
        }
      }
    }

    .submit {
      width: 600px;
      display: block;
      margin: 80px auto 60px;
      animation: identifier 1.5s infinite;

      @keyframes identifier {
        0% {
          transform: scale(1);
        }

        25% {
          transform: scale(1.15);
        }

        50% {
          transform: scale(1);
        }

        75% {
          transform: scale(1.15);
        }
      }
    }
  }

  .info {
    font-size: 24px;
    font-weight: 500;
    padding: 0 60px 100px;
    line-height: 40px;
    color: #ffffff;

    .title {
      text-align: center;
      opacity: 0.8;
    }

    .call {
      font-size: 30px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
    }

    .yellow {
      color: #ffff00;
    }

    .text {
      font-size: 24px;
      font-weight: 500;
      line-height: 40px;
      opacity: 0.8;
    }
  }

  .dialog {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;

    .success {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 700px;
      }
    }
  }
}
</style>
